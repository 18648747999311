import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import '../home/home.scss'
import Navbar from '../../components/navbar/Navbar'
import './profile.scss'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
)

const AdminProfile = () => {
  let value = localStorage.getItem('data')
  if (value) {
    value = JSON.parse(value)
    // console.log(value, 'value')
  } else {
    console.log('No data found in local storage')
  }
  // let firstName = value.firstName;
  let imageUrl = value ? value.image : null

  return (
    //     <div className='home'>
    //     <Sidebar />
    //     <div className='homeContainer'>
    //         <Navbar />

    //     <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column",top:122}}>
    //         <div style={{}}>
    //         {imageUrl && <img src={imageUrl} alt ='Admin Profile' style= {{height:120,width:120,borderRadius:120 }}/> }
    //         </div>
    //         <div style={{}}>
    //         <p style={{display:'flex',flexDirection:'row'}} >First Name:<p style = {{fontWeight:'bold'}}> {value.firstName}</p> </p>
    //          <p style={{display:'flex',flexDirection:'row'}}>Last Name:<p style = {{fontWeight:'bold'}}>  {value.lastName}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>email:<p style = {{fontWeight:'bold'}}>  {value.email}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>Address 1:<p style = {{fontWeight:'bold'}}>  {value.address_1}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>Address 2:<p style = {{fontWeight:'bold'}}>  {value.address_2}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>City:<p style = {{fontWeight:'bold'}}>  {value.city}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>Contact:<p style = {{fontWeight:'bold'}}>  {value.contact}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>Alt Contact:<p style = {{fontWeight:'bold'}}>  {value.alt_contact}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>Gender:<p style = {{fontWeight:'bold'}}>  {value.gender}</p></p>
    //          <p style={{display:'flex',flexDirection:'row'}}>Date of birth:<p style = {{fontWeight:'bold'}}>  {value.dateofbirth}</p></p>
    //         </div>

    //     </div>

    //     </div>
    //   </div>
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <Box sx={{ flexGrow: 1, margin: 2 }}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
              <Card
                sx={{
                  display: 'block',
                  transitionDuration: '0.3s',
                  height: '400px',
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <CardMedia
                      component="img"
                      borderRadius="123"
                      image={imageUrl}
                      alt="Paella dish"
                      sx={{
                        borderRadius: '50%',
                        height: '100px',
                        width: '100px',
                        margin : '10px',
                        border : '1px solid #73c0ff'
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Super Admin
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h5" component="div">
                        {value.firstName + ' ' + value.lastName}
                      </Typography>
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={8}>
              <Card
                sx={{
                  display: 'block',
                  transitionDuration: '0.3s',
                  height: '400px',
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    General Information
                  </Typography>
                  <Typography variant="h6" component="div">
                     Email : {value.email}
                  </Typography>
                  <Typography variant="h6" component="div">
                    Address 1 : {value.address_1}
                  </Typography>
                  <Typography variant="h6" component="div">
                    Address 2 : {value.address_2}
                  </Typography>
                  <Typography variant="h6" component="div">
                    City : {value.city}
                  </Typography>
                  <Typography variant="h6" component="div">
                    Gender : {value.gender}
                  </Typography>
                  <Typography variant="h6" component="div">
                    Date Of Birth : {value.dateofbirth}
                  </Typography>
                  <Typography variant="h6" component="div">
                    Contact : {value.contact}
                  </Typography>
                  <Typography variant="h6" component="div">
                    Alt Contact : {value.alt_contact}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default AdminProfile
