import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import StudentAttendanceDataTable from "../../components/datatable/StudentAttendanceDataTable";


const SingleStudent = () => {
    const [ data, setData,   ] = useState([])
  const location =useLocation()
  const { first_name, email, schoolName, section,id,student_class}  = location.state

  const fetchData =  () => {
    fetch('https://spo.apigo.click/services/getStudentbyid',{
      method: 'POST',//GET and ...
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ studentId: id })
     })
     .then((response)=>response.json()) //   <------ this line 
     .then((response)=>{
       setData(response.data)
      //  console.log(data,'hello data')
     });

   };
 
  //  console.log(data)
 
  useEffect(() => {
      fetchData()
}, [data]);

//////////////////////////////////attendanceofSingleStudents////////////////////////
const [ presentAverage, setPresentAverage ] = useState("")
  const dataAttend = [
    {
      attendance: "p",
  
      key: 1,
      status: 40,
      svg: { fill: "#D2F790" },
    },
    {
      attendance: "A",
      key: 2,
      status: 30,
      svg: { fill: "#A4C3DA" },
    },
    {
      attendance: "L",
      key: 3,
      status: 30,
      svg: { fill: "gray" },
    },
  ];


  useLayoutEffect(() => {
    function fetchData () {
      fetch(
        "https://spo.apigo.click/services/getstudentattendancedashboardbystudentid",
        {
          method: "POST", //GET and ...
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id: id }),
        }
      )
        .then((response) => response.json()) //   <------ this line
        .then((response) => {
          const students = response;
          // console.log(students.data,'hello from attendance')
          const attendanceOfAbsent = students.data.filter(
            (x) => x.attendance === "A"
          ).length;
  
          const attendanceOfPresent = students.data.filter(
            (x) => x.attendance === "P"
          ).length;
  
          const attendanceOfLeave = students.data.filter(
            (x) => x.attendance === "L"
          ).length;
  
          const attendanceOfTotalStudents = students.data.filter(
            (x) => x.attendance
          ).length;
  
          const absentaverage =
            (attendanceOfAbsent / attendanceOfTotalStudents) * 100;
  
          const presentaverage =
            (attendanceOfPresent / attendanceOfTotalStudents) * 100;
            setPresentAverage(Math.round(presentaverage))
          const leaveAverage =
            (attendanceOfLeave / attendanceOfTotalStudents) * 100;
          dataAttend[0].status = Math.round(absentaverage);
          dataAttend[1].status = Math.round(presentaverage);
          dataAttend[2].status = Math.round(leaveAverage);
        });
    };
   fetchData();
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////
return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top"> 

          <div className="left">
            <div className="left_one">
            <h1 className="title">Information</h1>
            <div className="item">
              <div className="details">
                <h1 className="itemTitle">{first_name + ' ' + data.last_name}</h1>
                <div className="detailItem">
                  <span className="itemKey">School Name:</span>
                  <span className="itemValue">
                    {schoolName} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Class:</span>
                  <span className="itemValue">{student_class}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Section:</span>
                  <span className="itemValue">{section}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Roll No.:</span>
                  <span className="itemValue">{data.roll_no}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Shift:</span>
                  <span className="itemValue">{data.currentshift}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Gender:</span>
                  <span className="itemValue">{data.gender}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Religion:</span>
                  <span className="itemValue">{data.religion}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Date of Birth:</span>
                  <span className="itemValue">{data.dateofbirth}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Father CNIC:</span>
                  <span className="itemValue">{data.father_cnic}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Contact:</span>
                  <span className="itemValue">{data.contact}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Emergency Contact:</span>
                  <span className="itemValue">{data.emergency_contact}</span>
                </div>
             
                
              </div>
            </div>
          </div>
          <div className="left_two">
          <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">Date of Admission:</span>
                  <span className="itemValue">{data.date_of_admission}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Disability:</span>
                  <span className="itemValue">{data.disability}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Disability Detail:</span>
                  <span className="itemValue">
                    {data.disabledetail} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Previous School:</span>
                  <span className="itemValue">
                    {data.lastschool} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Reason of previous school left:</span>
                  <span className="itemValue">
                    {data.reasonleft} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">City:</span>
                  <span className="itemValue">
                    {data.city} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address-1:</span>
                  <span className="itemValue">{data.address_1}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address-2:</span>
                  <span className="itemValue">{data.address_2}</span>
                </div>
             
                <div className="detailItem">
                  <span className="itemKey">Division:</span>
                  <span className="itemValue">
                    {data.selectDivision} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Districts:</span>
                  <span className="itemValue">
                    {data.selectedDistricts} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tehsil:</span>
                  <span className="itemValue">
                    {data.selectedTehsil} 
                  </span>
                </div>
                
              </div>
            </div>
          </div>

          </div>



          <div className="right">
          <h1 className='title'>Attendance</h1>
          <CircularProgressbar  className='circleBar'  value={presentAverage} text={presentAverage + "%"} strokeWidth={5} />

          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Assets</h1>
          <StudentAttendanceDataTable studentId = {id}/>
        </div>
      </div>
    </div>
  );
};

export default SingleStudent;
