import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Datatable = () => {
  const [data, setData] = useState([]);

  const fetchData = () => {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "Access-Control-Allow-Origin": "*" }),
    };
    fetch(
      "https://spo.apigo.click/services/getTeachers",
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          const newData = result.data.map((l) =>
            l._id ? { ...l, id: l._id } : null
          );
          setData(newData);
          // console.log(newData);
        },

        (error) => {
          console.log(error);
        }
      );
  };
  useEffect(() => {
    fetchData();
    // data.map((data) => console.log(data.school_name));
  }, []);

  // ------------------------------------Column--------------------------------------
  const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "staffName",
      headerName: "Teacher Name",
      width: 230,
      renderCell: (params) => {
        return <div className="cellWithImg">{params.row.staffName}</div>;
      },
    },
    {
      field: "schoolName",
      headerName: "School Name",
      width: 230,
    },

    {
      field: "formerProgramm",
      headerName: "Program",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.email}`}>
            {params.row.email}
          </div>
        );
      },
    },
  ];
  // ------------------------------------Column--------------------------------------
  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={{ pathname: "/home/teacherList/teacher" }}
              state={{
                id: params.row.id,
                staffName: params.row.staffName,
                email: params.row.email,
                schoolName: params.row.schoolName,
                formerProgramm: params.row.formerProgramm,
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Teachers
        {/* <Link to="/home/users/new" className="link">
          Add New
        </Link> */}
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
