import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccessibilityNew";
import ShoppingCartOutlinedIcon from "@mui/icons-material/PregnantWoman";
import MonetizationOnOutlinedIcon from "@mui/icons-material/Hail";
import React,{useState,useEffect} from 'react'

const Widget = ({ type }) => {
  const [schools, setSchools] = useState('')
  const [teachers, setTeachers] = useState('')
  const [schooladmin, setSchoolAdmin] = useState('')
  const [students, setStudents] = useState('')

  let data;
//  get all schools apiiii =======================================
  const fetchSchoolData = () => {
    fetch("https://spo.apigo.click/services/getschools", {
      method: "POST", //GET and ...
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json()) //   <------ this line
      .then((response) => {
        setSchools(response.data);

      });
  };
//  get all schools apiiii =======================================

// get all teachers apiiii========================================
const fetchTeacherData = () => {
  fetch("https://spo.apigo.click/services/getTeachers", {
    method: "POST", //GET and ...
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json()) //   <------ this line
    .then((response) => {
      setTeachers(response.data);
      
    });
};
// get all teachers apiiii========================================

// get all school admin api=======================================
const fetchSchoolAdmin = () => {
  fetch("https://spo.apigo.click/services/getSchooladmins", {
    method: "POST", //GET and ...
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json()) //   <------ this line
    .then((response) => {
      setSchoolAdmin(response.data);
      
    });
};
// get all school admin api=======================================

// get all students apiii=========================================
const fetchAllStudents = async () => {
 await fetch("https://spo.apigo.click/services/getstudent", {
    method: "POST", //GET and ...
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json()) //   <------ this line
    .then((response) => {
      setStudents(response.data);
      
    });
};
// console.log(students,'students data')
// get all students apiii=========================================

  useEffect(() => {
    fetchSchoolData();
    fetchTeacherData();
    fetchSchoolAdmin();
    fetchAllStudents();
  }, []);




  //temporary

  switch (type) {
    case "schools":
      data = {
        title: "Schools",
        link: "Total Schools",
        icon: (
          <HomeWorkIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "teachers":
      data = {
        title: "Teachers",
        link: "All Teachers",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "schooladmins":
      data = {
        title: "School Admin",
        link: "All School Admin",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "student":
      data = {
        title: "All Students",
        link: "All Students",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {type === 'schools' ? schools.length : 
          type === 'teachers' ? teachers.length:
           type === 'schooladmins' ? schooladmin.length: 
           type === 'student' ? students.length: null
           }
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
