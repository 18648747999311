import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/Table";
import { useLocation } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import { useLayoutEffect, useState, useEffect } from "react";
import TeacherAttendanceDataTable from "../../components/datatable/TeacherAttendanceDataTable";

const SingleTeacher = () => {
  const location =useLocation()
  const {staffName, email, schoolName, formerProgramm, id}  = location.state
const [ presentAverage, setPresentAverage ] = useState("")
const[newdata, setNewData]=useState([]);
  const data = [
    {
      attendance: "p",
  
      key: 1,
      status: 40,
      svg: { fill: "#D2F790" },
    },
    {
      attendance: "A",
      key: 2,
      status: 30,
      svg: { fill: "#A4C3DA" },
    },
    {
      attendance: "L",
      key: 3,
      status: 30,
      svg: { fill: "gray" },
    },
  ];


  useLayoutEffect(() => {
    function fetchData () {
      fetch(
        "https://spo.apigo.click/services/getteacherattendancedashboardbyteacherid",
        {
          method: "POST", //GET and ...
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id: id }),
        }
      )
        .then((response) => response.json()) //   <------ this line
        .then((response) => {
          const students = response;
          const attendanceOfAbsent = students.data.filter(
            (x) => x.attendance === "A"
          ).length;
  
          const attendanceOfPresent = students.data.filter(
            (x) => x.attendance === "P"
          ).length;
  
          const attendanceOfLeave = students.data.filter(
            (x) => x.attendance === "L"
          ).length;
  
          const attendanceOfTotalStudents = students.data.filter(
            (x) => x.attendance
          ).length;
  
          const absentaverage =
            (attendanceOfAbsent / attendanceOfTotalStudents) * 100;
  
          const presentaverage =
            (attendanceOfPresent / attendanceOfTotalStudents) * 100;
            setPresentAverage(Math.round(presentaverage))
          const leaveAverage =
            (attendanceOfLeave / attendanceOfTotalStudents) * 100;
          data[0].status = Math.round(absentaverage);
          data[1].status = Math.round(presentaverage);
          data[2].status = Math.round(leaveAverage);
        });
    };
   fetchData();
  }, []);

  const fetchData = () => {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ teacherId: id }),
    };
    fetch(
      "https://spo.apigo.click/services/getTeacherbyid",
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {setNewData(result.data);
        // console.log(result.data);
        },

        (error) => {
          console.log(error);
        }
      );
  };
  useEffect(() => {
    fetchData();
    // data.map((data) => console.log(data.school_name));
  }, []);


  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
             
            <div className="left_one">
            <h1 className="title">Information</h1>
            <div className="item">
            
              <div className="details">
                <h1 className="itemTitle">{staffName}</h1>
                <div className="detailItem">
                  <span className="itemKey">Staff Position:</span>
                  <span className="itemValue">
                    {newdata.staffPosition} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Program:</span>
                  <span className="itemValue">{formerProgramm}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">School Name:</span>
                  <span className="itemValue">
                    {schoolName} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Father Name:</span>
                  <span className="itemValue">{newdata.father_name}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Gender:</span>
                  <span className="itemValue">{newdata.gender}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Religion:</span>
                  <span className="itemValue">{newdata.religion}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Date of Birth:</span>
                  <span className="itemValue">{newdata.dateofbirth}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">CNIC:</span>
                  <span className="itemValue">
                    {newdata.cnic} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Marital Status:</span>
                  <span className="itemValue">
                    {newdata.maritalStatus} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Contact-1:</span>
                  <span className="itemValue">
                    {newdata.contact} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Emergency Contact:</span>
                  <span className="itemValue">
                    {newdata.alt_contact} 
                  </span>
                </div>
              
                <div className="detailItem">
                  <span className="itemKey">Address_1:</span>
                  <span className="itemValue">
                    {newdata.address_1} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address_2:</span>
                  <span className="itemValue">
                    {newdata.address_2} 
                  </span>
                </div>
              
                <div className="detailItem">
                  <span className="itemKey">Division:</span>
                  <span className="itemValue">
                    {newdata.selectDivision} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Districts:</span>
                  <span className="itemValue">
                    {newdata.selectedDistricts} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tehsil:</span>
                  <span className="itemValue">
                    {newdata.seletctedTehsil} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Taching Class:</span>
                  <span className="itemValue">
                    {newdata.teachingClass} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Taching Medium:</span>
                  <span className="itemValue">
                    {newdata.teachingmedium} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Taching Subject:</span>
                  <span className="itemValue">
                    {newdata.teachingSubject} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Qualification:</span>
                  <span className="itemValue">
                    {newdata.teacherQualification} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Professional Qualification:</span>
                  <span className="itemValue">
                    {newdata.teacherprofessionalqualification} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Teaching Experience:</span>
                  <span className="itemValue">
                    {newdata.teachingExperience} 
                  </span>
                </div>
           
                
              </div>
            </div>
          </div>
          <div className="left_two">
            <div className="item">
            <div className="details">

            <div className="detailItem">
                  <span className="itemKey">Experience Duration:</span>
                  <span className="itemValue">
                    {newdata.experienceDuration} 
                  </span>
                </div>
            <div className="detailItem">
                  <span className="itemKey">Trainings:</span>
                  <span className="itemValue">
                    {newdata.teacherTraining} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Trained Subject:</span>
                  <span className="itemValue">
                    {newdata.trainInWhichSubject} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">MentionTraining:</span>
                  <span className="itemValue">{newdata.mentionTraining}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">LSU Training Date:</span>
                  <span className="itemValue">{newdata.lsuTrainingDate}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Subject Speciality:</span>
                  <span className="itemValue">{newdata.SubjectSpec}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">LSU Training Date:</span>
                  <span className="itemValue">{newdata.lsuTrainingDate}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Contract Start:</span>
                  <span className="itemValue">
                    {newdata.contractstart} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Contract End:</span>
                  <span className="itemValue">
                    {newdata.contractend} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Date of Joining:</span>
                  <span className="itemValue">
                    {newdata.dateofJoining} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Current Salary:</span>
                  <span className="itemValue">
                    {newdata.currentSalary} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Salary Payment Method:</span>
                  <span className="itemValue">
                    {newdata.salaryPaymentMethod} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Account Title:</span>
                  <span className="itemValue">
                    {newdata.accounttitle} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Bank Name:</span>
                  <span className="itemValue">
                    {newdata.bankname} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Bank Account Number:</span>
                  <span className="itemValue">
                    {newdata.bankaccountnumber} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">IBAN Account:</span>
                  <span className="itemValue">
                    {newdata.ibanAccount} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Bank City:</span>
                  <span className="itemValue">
                    {newdata.bankcity} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Bank District:</span>
                  <span className="itemValue">
                    {newdata.bankdistrict} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Vaccinated:</span>
                  <span className="itemValue">
                    {newdata.vaccinated} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Vaccine Shots:</span>
                  <span className="itemValue">
                    {newdata.vaccineshots} 
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Vacinated Status:</span>
                  <span className="itemValue">
                    {newdata.vacinatedstatus} 
                  </span>
                </div>
                
              </div>
            </div>
          </div>



          </div>
          <div className="right">
          <h1 className='title'>Attendance</h1>
          <CircularProgressbar className='circleBar' value={presentAverage} text={presentAverage + "%" } strokeWidth={5} />

          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Attendance</h1>
          <TeacherAttendanceDataTable teacherId={id}/>
        </div>
      </div>
    </div>
  );
};

export default SingleTeacher;
