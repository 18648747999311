import "./datatable.scss";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


// import { userRows } from "../../datatablesource";
import { useEffect, useState } from "react";


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{
    fileName: 'Teacher Attendance',
  }}
  printOptions={{
    hideFooter: true,
    hideToolbar: true,
  }}
  />
    </GridToolbarContainer>
  );
}



const TeacherAttendanceDataTable = (teacherId) => {
    const[assets, setassets]= useState("")


  const AssetData =  () => {
    fetch("https://spo.apigo.click/services/getteacherattendancedashboardbyteacherid", {
      method: "POST", //GET and ...
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: teacherId.teacherId}),
    })
      .then((response) => response.json()) //   <------ this line
      .then((response) => {
        const newData = response.data.map(l => l._id ? {...l, id: l._id} : null)
        setassets(newData)
      });
  };
  
  useEffect(() => {
    AssetData()
  });


const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "staffName",
      headerName: "staffName",
      width: 230,
      renderCell: (params) => { 
        return (
          <div className="cellWithImg">
          
            {params.row.staffName}
          </div>
        );
      },
    },
    {
      field: "attendance",
      headerName: "Attendance",
      width: 230,
    
    },
  
    {
      field: "createdAt",
      type: 'date',
      headerName: "createdAt",
      width: 300,
      valueGetter: (params) => {
        // Transform the createdAt string into a Date object
        return new Date(params.value);
      },
    },
  
  ];
  const handleDelete = (id) => {
    setassets(assets.filter((item) => item.id !== id));
  };


  return (
    <div className="datatable">
    <DataGrid
        className="datagrid"
        rows={assets}
        columns={userColumns.concat()}
        pageSize={9}
        rowsPerPageOptions={[9]}
        
        components={{ Toolbar: CustomToolbar }}
      />
     
    </div>
  );
};

export default TeacherAttendanceDataTable;
