import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import AssetDatatable from "../../components/datatable/AssetDatable";

const Single = () => {
  const location = useLocation()
  const { from, schoolCode, address_1, schoolId, FormerProgram } = location.state
  const [studentpresentAverage, setStudentPresentAverage] = useState("")
  const [teacherpresentAverage, setTeacherPresentAverage] = useState("")
  const [schoolData, setSchoolData] = useState("")

  const data = [
    {
      attendance: "p",

      key: 1,
      status: 40,
      svg: { fill: "#D2F790" },
    },
    {
      attendance: "A",
      key: 2,
      status: 30,
      svg: { fill: "#A4C3DA" },
    },
    {
      attendance: "L",
      key: 3,
      status: 30,
      svg: { fill: "gray" },
    },
  ];


  const fetchData = () => {
    fetch(
      "https://spo.apigo.click/services/getstudentsattendancedashboardbyschoolid",
      {
        method: "POST", //GET and ...
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ schoolId: schoolId }),
      }
    )
      .then((response) => response.json()) //   <------ this line
      .then((response) => {
        const students = response;
        const attendanceOfAbsent = students.data.filter(
          (x) => x.attendance === "A"
        ).length;

        const attendanceOfPresent = students.data.filter(
          (x) => x.attendance === "P"
        ).length;

        const attendanceOfLeave = students.data.filter(
          (x) => x.attendance === "L"
        ).length;

        const attendanceOfTotalStudents = students.data.filter(
          (x) => x.attendance
        ).length;

        const absentaverage =
          (attendanceOfAbsent / attendanceOfTotalStudents) * 100;

        const presentaverage =
          (attendanceOfPresent / attendanceOfTotalStudents) * 100;
        setStudentPresentAverage(Math.round(presentaverage))

        const leaveAverage =
          (attendanceOfLeave / attendanceOfTotalStudents) * 100;

        data[0].status = Math.round(absentaverage);
        data[1].status = Math.round(presentaverage);
        data[2].status = Math.round(leaveAverage);
      });
  };

  useLayoutEffect(() => {
    fetchData();
    fetchTeacherData();
    fetchSchoolData();
  }, []);


  const fetchTeacherData = () => {
    fetch(
      "https://spo.apigo.click/services/getteacherattendancedashboardbyschoolid",
      {
        method: "POST", //GET and ...
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ schoolId: schoolId }),
      }
    )
      .then((response) => response.json()) //   <------ this line
      .then((response) => {
        const students = response;
        const attendanceOfAbsent = students.data.filter(
          (x) => x.attendance === "A"
        ).length;

        const attendanceOfPresent = students.data.filter(
          (x) => x.attendance === "P"
        ).length;

        const attendanceOfLeave = students.data.filter(
          (x) => x.attendance === "L"
        ).length;

        const attendanceOfTotalStudents = students.data.filter(
          (x) => x.attendance
        ).length;

        const absentaverage =
          (attendanceOfAbsent / attendanceOfTotalStudents) * 100;

        const presentaverage =
          (attendanceOfPresent / attendanceOfTotalStudents) * 100;
        setTeacherPresentAverage(Math.round(presentaverage))

        const leaveAverage =
          (attendanceOfLeave / attendanceOfTotalStudents) * 100;

        data[0].status = Math.round(absentaverage);
        data[1].status = Math.round(presentaverage);
        data[2].status = Math.round(leaveAverage);
      });
  };

  const fetchSchoolData = () => {
    fetch('https://spo.apigo.click/services/getschoolbyid', {
      method: 'POST',//GET and ...
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ schoolId: schoolId })
    })
      .then((response) => response.json()) //   <------ this line 
      .then((response) => {
        response.data.map((l)=> setSchoolData(l))
      });

  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="left_one">
              <h1 className="title">Information</h1>
              <div className="item">

                <div className="details">
                  <h1 className="itemTitle">{from}</h1>
                  <div className="detailItem">
                    <span className="itemKey">School Code:</span>
                    <span className="itemValue">{schoolCode}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Programm:</span>
                    <span className="itemValue">{FormerProgram}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Address:</span>
                    <span className="itemValue">
                      {address_1}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Category:</span>
                    <span className="itemValue">
                      {schoolData.categories}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Level:</span>
                    <span className="itemValue">{schoolData.level}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Address_1:</span>
                    <span className="itemValue">
                      {address_1}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Address_2:</span>
                    <span className="itemValue">
                      {address_1}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">District:</span>
                    <span className="itemValue">{schoolData.selectedDistricts}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Tehsil:</span>
                    <span className="itemValue">{schoolData.selectedTehsil}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">UC:</span>
                    <span className="itemValue">
                      {schoolData.uc}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Village:</span>
                    <span className="itemValue">
                      {schoolData.village}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Building Owner:</span>
                    <span className="itemValue">
                      {schoolData.BuildingOwner}
                    </span>
                  </div>
              
                  <div className="detailItem">
                    <span className="itemKey">Partner Name:</span>
                    <span className="itemValue">
                      {schoolData.partnername}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Electricity:</span>
                    <span className="itemValue">
                      {schoolData.Electricity}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Source of Electricity:</span>
                    <span className="itemValue">
                      {schoolData.SourceElectricity}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">School Plot Size:</span>
                    <span className="itemValue">
                      {schoolData.SchoolPlotSize}
                    </span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Library:</span>
                    <span className="itemValue">
                      {schoolData.Library}
                    </span>
                  </div>

                </div>
              </div>
            </div>

            <div className="left_two">
              <div className="item">

                <div className="details">

          
                <div className="detailItem">
                    <span className="itemKey">Science Lab:</span>
                    <span className="itemValue">
                      {schoolData.ScienceLab}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Ventilation:</span>
                    <span className="itemValue">
                      {schoolData.Ventilation}
                    </span>
                  </div>
  
                  <div className="detailItem">
                    <span className="itemKey">SEF Sign Board:</span>
                    <span className="itemValue">
                      {schoolData.SEFSignBoard}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Total Class Rooms:</span>
                    <span className="itemValue">
                      {schoolData.TotalClassRooms}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Total Soft Rooms:</span>
                    <span className="itemValue">
                    {schoolData.TotalSoftBoards}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">AvRooms:</span>
                    <span className="itemValue">
                    {schoolData.AvRooms}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Computer Room:</span>
                    <span className="itemValue">
                    {schoolData.ComputerRoom}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">HeadTeacher Office:</span>
                    <span className="itemValue">
                    {schoolData.HeadTeacherOffice}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Teachers Resource Room:</span>
                    <span className="itemValue">
                    {schoolData.TeachersResourceRoom}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Drinking Water:</span>
                    <span className="itemValue">
                    {schoolData.DrinkingWater}
                    </span>
                  </div>
                         
                
                  <div className="detailItem">
                    <span className="itemKey">ECE:</span>
                    <span className="itemValue">
                    {schoolData.ECE}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Student Furniture:</span>
                    <span className="itemValue">
                    {schoolData.StudentFurniture}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Teacher Furniture:</span>
                    <span className="itemValue">
                    {schoolData.TeacherFurniture}
                    </span>
                  </div>
                
                  <div className="detailItem">
                    <span className="itemKey">Total Toilets:</span>
                    <span className="itemValue">
                    {schoolData.TotalToilets}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Total Functional Toilets:</span>
                    <span className="itemValue">
                    {schoolData.FunctionalToilets}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Total Non-Functional Toilets:</span>
                    <span className="itemValue">
                    {schoolData.NonFunctionalToilets}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Draining System:</span>
                    <span className="itemValue">
                    {schoolData.DrainingSystem}
                    </span>
                  </div>
         


                </div>
              </div>

            </div>
          </div>
          <div className="right">
            <h1 className='title'>Student Attendance</h1>
            <CircularProgressbar className='circleBarStudent' value={studentpresentAverage} text={studentpresentAverage + "%"} strokeWidth={5} />
          </div>
          <div className="right">
            <h1 className='title'>Teacher Attendance</h1>
            <CircularProgressbar className='circleBarTeacher' value={teacherpresentAverage} text={teacherpresentAverage + "%"} strokeWidth={5} />
          </div>
        </div>
        {/* <div className="bottom">
          <h1 className="title">Assets</h1>
          <AssetDatatable />
        </div> */}
      </div>
    </div>

  );
};

export default Single;
