import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import React, { PureComponent, useEffect, useState} from 'react';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';



const data = [
  {
    attendance: "p",

    key: 1,
    status: 40,
    svg: { fill: "#D2F790" },
  },
  {
    attendance: "A",
    key: 2,
    status: 30,
    svg: { fill: "#A4C3DA" },
  },
  {
    attendance: "L",
    key: 3,
    status: 30,
    svg: { fill: "gray" },
  },
];



const Featured = () => {
  const [present, setPresent] = useState("")
  const [absent, setAbsent] = useState("")
  const [leave, setLeave] = useState("")

  const fetchData = async () => {
    const resp = await fetch(`https://spo.apigo.click/services/getstudentsattendancedashboard`);
    const students = await resp.json();
    const attendanceOfAbsent = students.data.filter(x => x.attendance=='A').length;
    const attendanceOfPresent = students.data.filter(x => x.attendance=='P').length;
    
    const attendanceOfLeave = students.data.filter(x => x.attendance=='L').length;
    const attendanceOfTotalStudents = students.data.filter(x => x.attendance).length;
    
    const absentaverage = attendanceOfAbsent/attendanceOfTotalStudents*100;
    
    const presentaverage = attendanceOfPresent/attendanceOfTotalStudents*100;
    
    const leaveAverage = attendanceOfLeave/attendanceOfTotalStudents*100;
    
        data[0].status = Math.round(presentaverage);
        data[1].status = Math.round(absentaverage);
        data[2].status = Math.round(leaveAverage);
        setPresent(data[0].status )
        // console.log(present)
        setAbsent(data[1].status )
        setLeave(data[2].status )
  };
  const data01 = [
    { name: "Present", value: present },
    { name: "Absent", value: absent },
    { name: "Leave", value: leave },
   
  ];
  useEffect(() => {
   fetchData();
  
  }, []);

 
  
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Student Attendance in %</h1>
      </div>
      <div className="bottom">
      <PieChart width={300} height={300}
      >
      <Pie
        dataKey="value"
        isAnimationActive={false}
       
        data={data01}
        cx={130}
        cy={150}
        outerRadius={80}
        fill="#5062BD"
        label
      />
   
      <Tooltip />
    </PieChart>

    
   
      </div>
    </div>
  );
};

export default Featured;
