import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import TeacherList from "./pages/teacherList/TeacherList";
import Single from "./pages/single/Single";
import SingleTeacher from "./pages/single/SingleTeacher";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import StudentList from "./pages/studentlList/StudentList.jsx";
import SingleStudent from "./pages/single/SingleStudent";
import HeadTeacherList from "./pages/headteacherList/HeadTeacherList.jsx";
import SingleHeadTeacher from "./pages/single/SingleHeadTeacher";
import AdminProfile from "./pages/Profile/AdminProfile";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/home">
            <Route index element={<Home />} />
            <Route path="/home/users">
              <Route index element={<List />} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="teacherList">
              <Route index element={<TeacherList />} />
              <Route path=":productId" element={<SingleTeacher />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="studentList">
              <Route index element={<StudentList />} />
              <Route path=":productId" element={<SingleStudent />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="headteacherList">
              <Route index element={<HeadTeacherList />} />
              <Route path=":productId" element={<SingleHeadTeacher/>} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />              
            </Route>

            <Route path="AdminProfile">
              <Route index element={<AdminProfile />} />
                            
            </Route>



          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
