import "./datatable.scss";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
// import { userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{
    fileName: 'Schools',
  }}
  printOptions={{
    hideFooter: true,
    hideToolbar: true,
  }}
  />
    </GridToolbarContainer>
  );
}


const Datatable = () => {
  const [data, setData] = useState([]);

  const fetchData = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({  'Access-Control-Allow-Origin': '*' })
  };
    fetch("https://spo.apigo.click/services/getschools",
     requestOptions
     )
      .then(res => res.json())
      .then(
        (result) => {
          const newData = result.data.map(l => l._id ? {...l, id: l._id} : null)
          setData(newData)
          // console.log(newData)
        },
        
        (error) => {
          console.log(error)
        }
      )
  }
  useEffect(() => {
    fetchData()
  // data.map(data => console.log(data.school_name))
  },[]);



const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "school_name",
      headerName: "School Name",
      width: 290,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
          
            {params.row.school_name}
          </div>
        );
      },


      

    },
    {
      field: "schoolcode",
      headerName: "School Code",
      width: 230,
    
    },
  
    {
      field: "formerProgram",
      headerName: "Program",
      width: 100,
    },
    {
      field: "address_1",
      headerName: "Address",
      width: 160,
   
    },
  ];

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 140,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={{ pathname: "/home/users/test",
             }} state={{ from: params.row.school_name, schoolCode : params.row.schoolcode, 
              FormerProgram: params.row.formerProgram, address_1: params.row.address_1, schoolId : params.row.id}} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];


  

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Schools List
        {/* <Link to="/home/users/new" className="link">
          Add New School
        </Link> */}
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar
        }}
      />
    </div>
  );
};

export default Datatable;
