import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";


const SingleHeadTeacher = () => {
  const location =useLocation()
  // console.log(location._id,'hello id')
  const {first_name, email, schoolName, formerProgram, id,gender,}  = location.state
const [ presentAverage, setPresentAverage ] = useState("")
 

  const [data, setData] = useState([]);

  
  const fetchData = () => {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "Access-Control-Allow-Origin": "*" }),
    };
    fetch(
      "https://spo.apigo.click/services/getadmins",
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          const newData = result.data.map((l) =>
            l._id ? { ...l, id: l._id } : null
          );
          setData(newData);
          // console.log(newData,'school data ');
        },

        (error) => {
          console.log(error);
        }
      );
  };
  useEffect(() => {
    fetchData();

  }, []);

 

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Information</h1>
            <div className="item">
            
              <div className="details" style={{marginTop:20}}>
                  <h1 className="itemTitle">{first_name  }</h1>
                <div className="detailItem">
                  {/* <span className="itemKey">Gender:</span> */}
                  <span className="itemValue">{gender}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Program:</span>
                  <span className="itemValue">{formerProgram}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">School Name:</span>
                  <span className="itemValue">
                    {schoolName} 
                  </span>
                </div>
                
              </div>
            </div>
          </div>
         
        </div>
       
      </div>
    </div>
  );
};

export default SingleHeadTeacher;
