// import React, { Component } from 'react'
import CustomInput from './CustomInput'
import Button from './Button'
import './login.scss'
import logo from '../../assets/logo.png'
import { useNavigate } from "react-router-dom";
import React,{useState} from 'react'

export default function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errorMsg, setErrorMsg] = useState('')
  const navigate = useNavigate()
  const handleChange = (e) => {
    
    setFormData({ ...formData, [e.currentTarget.id]: e.currentTarget.value });
  }


    
 const handleApi = () => {
  const { email, password } = formData;
    fetch('https://spo.apigo.click/services/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email, password}),
      }).then((res)=>res.json())
      .then((data) =>{
        // console.log(data.data._id, 'loging data ')
        if(data.message === "authenticated"){
          localStorage.setItem( 'data' ,JSON.stringify(data.data));
          navigate('/home')
        }else if(data.message==='Invalid username or password'){
          setErrorMsg('Invalid username or password')
        }
      })
  }
  return (
    <div className="App">
    <div className="top">
      <img src={logo} alt="Logo" className="logoimage" />
      <h1>SPO</h1>
    </div>

    <form className="form" style={{marginBottom:'30px'}}>
      <p className="text">Sign In</p>
      <div style={{color:'red'}}>
      {errorMsg}

      </div>

      <CustomInput
        labelText="Email"
        id="email"
        formControlProps={{
          fullWidth: true,
        }}
        handleChange={handleChange}
        type="text"
      />
      <CustomInput
        labelText="Password"
        id="password"
        formControlProps={{
          fullWidth: true,
        }}
        handleChange={handleChange}
        type="password"
      />
      <Button onClick={() => handleApi()} type="button" color="primary" className="form__custom-button">
        Log in
      </Button>
    </form>
    <a href="sef_schools.apk" download="sef_schools.apk" target='_blank' type="button" color="primary" className="form__custom-button" style={{background:'black', color:'white',padding:'10px',textDecoration:'none',border:'1px solid black',borderRadius:'10px'}}>
        Download App
      </a>
  </div>
  )
}
